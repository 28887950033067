import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const userModule = {
  state: () => ({
    userCredentials: {
      username: '',
      companyName: '',
      id: '',
      isAdmin: false,
      distributorName: '',
      rolesUser: {},
    },
  }),
  mutations: {
    setCredentials(state, user){
      if(!!user){
        state.userCredentials.username = user.username;
        state.userCredentials.companyName = user.companyName;
        state.userCredentials.id = user.id;
        state.userCredentials.isAdmin = user.isAdmin;
        state.userCredentials.distributorName = user.distributorName;
        state.userCredentials.rolesUser = user.rolesUser;

      }
      else{
        state.userCredentials.username = '';
        state.userCredentials.companyName = '';
        state.userCredentials.id = '';
        state.userCredentials.isAdmin = false;
        state.userCredentials.distributorName = '';
        state.userCredentials.rolesUser = {};
      }
    },
  },
  actions: {},
  getters: {}
}

export default new Vuex.Store({
  state: {
    language: 'it',
    dataNeededAfterComponentReload: null
  },
  getters: {
    getLanguage(state) {
      return state.language
    }
  },
  mutations: {
    setLanguage(state, newLanguage) {
      state.language = newLanguage;
    },
    setDataNeededAfterComponentReload(state, params) {
      state.dataNeededAfterComponentReload = params;
    }
  },
  actions: {
  },
  modules: {
    userModule: userModule
  },
  plugins: [createPersistedState({
    storage: window.sessionStorage,
  })],
})
