<template>
  <div>
    <v-data-table
      v-if="modOfDisplay === 'table'"
      v-model="selectedClients"
      :height="dynamicHeight"
      :headers="headers"
      :items="items"
      fixed-header
      :single-select="false"
      :show-select="true"
      @input="inputEvent"
      :loading="loadingData"
      :search="search"
      item-class="row-clients-table"
      :footer-props="{
        'items-per-page-text': getTranslation('ROWS_PER_PAGE')
       }"
      @click:row="rowCLicked"
      :options.sync="optionsPagination"
    >
      <template v-slot:[`header.data-table-select`]>
        <div class="text-start">
          <v-btn
            style="border-radius: 10px; align-content: start"
            @click="modOfDisplay = modOfDisplay === 'table' ? 'groupedByReseller' : 'table'"
            small
            color="primary"
          >
            <v-icon>
              mdi-account-group
            </v-icon>
          </v-btn>
        </div>
      </template>
      <template v-slot:header.companyName>
        {{ getTranslation('NAME') }}
      </template>
      <template v-slot:item.data-table-select="{ isSelected }">
        <div class="d-flex justify-center">
          <v-checkbox
            :input-value="isSelected"
            hide-details="auto"
          ></v-checkbox>
        </div>
      </template>
      <template v-slot:item.companyName="{item,index}">
        <div class="client">
          {{
            ((!!optionsPagination.page && optionsPagination.page > 1) ?
              String(Number(optionsPagination.itemsPerPage * (optionsPagination.page - 1)) + Number(index + 1))
              : (index + 1)) + ". " + item.companyName
          }}
        </div>
      </template>
    </v-data-table>
    <div v-else-if="modOfDisplay === 'groupedByReseller' && groupedClientsAreFetched">
      <div class="pt-2 pl-4 d-flex align-center">
        <v-btn
          style="border-radius: 10px; align-content: start"
          @click="modOfDisplay = modOfDisplay === 'table' ? 'groupedByReseller' : 'table'"
          small
          color="secondary"
        >
          <v-icon>
            mdi-account-group
          </v-icon>
        </v-btn>
        <!--        <v-btn
                  icon
                  :color="modOfDisplay === 'table' ? 'white' : 'primary'"
                  @click="modOfDisplay = modOfDisplay === 'table' ? 'groupedByReseller' : 'table'"
                >
                  <v-icon>
                    mdi-account-group
                  </v-icon>
                </v-btn>-->
        <div class="ml-2" style="font-size: 18px; color: #63adf2;">{{ getTranslation('GROUPED_BY_RESELLER') }}</div>
      </div>
      <v-sheet :style="{'height': dynamicHeight === 330 ? '350px' : '35vh','overflow-y': 'scroll'}" class="pt-1">
        <v-expansion-panels v-for="resellerName of Object.keys(itemsGroupedByReseller)" :key="resellerName">
          <v-expansion-panel style="border-color: #63adf2; border-style: solid">
            <v-expansion-panel-header class="py-2">
                <div class="d-flex align-center">
                  <v-checkbox
                    hide-details="auto"
                    :input-value="isCheckboxResellerTrue(resellerName)"
                    @click.stop.prevent="isCheckboxResellerTrue(resellerName) ?
                      selectedClients = selectedClients.filter(el => el.resellerName !== resellerName) :
                      (() => {
                        selectedClients = selectedClients.filter(el => el.resellerName !== resellerName);
                        selectedClients.push(...items.filter(el => el.resellerName === resellerName));
                      })()
                      "
                  />
                  {{ resellerName }}
                  <v-spacer/>
                  <div class="d-flex align-center px-4 mr-3" style="border-color: #63adf2; border-style: solid; border-radius: 10px">
                    <div style="font-size: 18px" class="py-2">
                      {{ selectedClients.filter(el => el.resellerName === resellerName).length }}
                    </div>
                    <div style="font-size: 26px" class="py-2">
                      {{ "/" }}
                    </div>
                    <div style="font-size: 18px" class="py-2">
                      {{ items.filter(el => el.resellerName === resellerName).length }}
                    </div>
                  </div>
                </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div style="max-height: 250px; overflow-y: scroll; border-top: solid #63adf2">
                <div
                  class="d-flex align-center ml-3 py-1"
                  v-for="(clientOfReseller, index) of itemsGroupedByReseller[resellerName]"
                  :key="clientOfReseller.id"
                  @click="rowCLicked(clientOfReseller)"
                  style="cursor: pointer"
                >
                  <v-checkbox
                    hide-details="auto"
                    :input-value="!!selectedClients.find(el => el.id === clientOfReseller.id)"
                    @click.stop.prevent="!!selectedClients.find(el => el.id === clientOfReseller.id) ?
                      selectedClients = selectedClients.filter(el => el.id !== clientOfReseller.id) :
                      selectedClients.push(clientOfReseller)"
                  ></v-checkbox>
                  {{
                    ((!!optionsPagination.page && optionsPagination.page > 1) ?
                      String(Number(optionsPagination.itemsPerPage * (optionsPagination.page - 1)) + Number(index + 1))
                      : (index + 1)) + ". " + clientOfReseller.companyName
                  }}
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-sheet>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import translateService from "@/globalServices/translate";

export default {
  name: "ClientTable",
  data: () => ({
    headers: [
      {value: "companyName", sortable: false},
    ],
    items: [],
    dynamicHeight: '',
    selectedClients: [],
    optionsPagination: {},
    modOfDisplay: 'table',
    groupedClientsAreFetched: false,
    itemsGroupedByReseller: {},
    loadingData: false,
  }),
  props: {
    bus: {
      type: Object,
      default: () => {
      }
    },
    windowHeight: {
      type: Number,
    },
    search: {
      type: String,
      default: ''
    }
  },
  mounted() {
    this.loadingData = true;
    this.dynamicHeight = this.windowHeight > 1000 ? '35vh' : 330;
    axios.defaults.withCredentials = true;
    axios
      .get(process.env.VUE_APP_BASEURL + "/api/client", {
        params:
          {
            filters: {
              selectAttributes: ['resellers.name as resellerName', 'clients.id', 'baseUrl', 'host', 'companyName'],
            }
          }
      })
      .then((response) => {
        if (response?.data?.success)
          this.items = response.data.payload;
        this.loadingData = false;
      })
      .catch((err) => {
        //unauthorized case
        console.log(err)
        if (err.response && err.response.status === 401) {
          this.$store.commit('setCredentials', null);
          this.$router.push({name: 'Login'}).catch(() => {
          })
        }
        this.loadingData = false;
      });
    this.bus.$on("clientsSelectedByMapChart", (clientsSelected) => {
      this.selectedClients = clientsSelected;
    })
  },
  methods: {
    rowCLicked(item) {
      if (!!this.selectedClients && !this.selectedClients.find(el => el.id === item.id))
        this.selectedClients.push(item);
      else if (!!this.selectedClients && !!this.selectedClients.find(el => el.id === item.id)) {
        this.selectedClients = this.selectedClients.filter(el => el.id !== item.id)
      }
    },
    inputEvent() {
      this.bus.$emit('clientsSelected', this.selectedClients);
      //this.bus.$emit('clientsSelectedByClientTable', this.selectedClients);
      this.$emit('clientSelectedForSubtitle');
    },
    isCheckboxResellerTrue(resellerName){
      return this.selectedClients.filter(el => el.resellerName === resellerName).length > 0 &&
      this.selectedClients.filter(el => el.resellerName === resellerName).length === this.items.filter(el => el.resellerName === resellerName).length
    },
    /**
     * Translates the key with the language chosen
     * @param {string} key -key to translate
     * @returns {string} key translated
     */
    getTranslation(key) {
      return translateService.getTranslation(this.languageChosen, key);
    },
    fetchClientsGroupedByReseller() {
      this.groupedClientsAreFetched = false;
      this.itemsGroupedByReseller = {};
      this.items.forEach(client => {
        if (!this.itemsGroupedByReseller[client.resellerName])
          this.itemsGroupedByReseller[client.resellerName] = [client];
        else
          this.itemsGroupedByReseller[client.resellerName].push(client);
      });
      this.groupedClientsAreFetched = true;
    }
  },
  watch: {
    modOfDisplay(newVal) {
      if (newVal === 'groupedByReseller') {
        this.fetchClientsGroupedByReseller();
        this.$emit('hideSearchBox');
      } else
        this.$emit('showSearchBox');
    },
    selectedClients(newVal, oldVal) {
      if (this.modOfDisplay === 'groupedByReseller') {
        this.bus.$emit('clientsSelected', this.selectedClients);
        //this.bus.$emit('clientsSelectedByClientTable', this.selectedClients);
        this.$emit('clientSelectedForSubtitle');
      }
    },
  },
  computed: {
    languageChosen() {
      return this.$store.state.language;
    },
  }
}
</script>
<style scoped lang=scss>
  ::v-deep {
    .v-input--selection-controls {
      margin-top: 0;
      margin-bottom: 6px;
    }
  }
  ::v-deep {
     .client {
       cursor: pointer;
     }
   }
</style>
