<template>
  <v-select
    color="white"
    :items="languages"
    :value="languageChosen"
    item-value="name"
    style="max-width: 60px"
    hide-details="auto"
    class="mt-0 pt-0"
    @change="setNewLanguage"
    @click.stop
  >
    <template v-slot:selection="{ item }">
      <div class="d-flex align-center">
        <v-img
          :src="item.image"
          max-height="30"
          max-width="30"
        />
      </div>
    </template>
    <template v-slot:item="{ item }">
      <div class="d-flex align-center">
        <v-img
          :src="item.image"
          max-height="30"
          max-width="30"
        />
      </div>
    </template>
  </v-select>
</template>
<script>
export default {
  name: "LanguageSelector",
  data() {
    return {
      languages: [
        {name: "it", image: require("@/assets/images/italyFlag.jpeg")},
        {name: "en", image: require("@/assets/images/ukFlag.png")},
        {name: "de", image: require("@/assets/images/germanyFlag.jpeg")},
        {name: "es", image: require("@/assets/images/spainFlag.jpeg")},
      ]
    }
  },
  created() {
    if (window.localStorage.getItem('languageErmetix') &&
      ['it', 'en', 'de', 'es'].includes(window.localStorage.getItem('languageErmetix'))
    )
      this.$store.commit('setLanguage', window.localStorage.getItem('languageErmetix'));

  },
  methods: {
    setNewLanguage(newLanguage) {
      window.localStorage.setItem('languageErmetix', newLanguage);
      this.$store.commit('setLanguage', newLanguage);
    }
  },
  computed: {
    languageChosen() {
      return this.$store.state.language;
    }
  }
}

</script>


<style scoped>

</style>