import router from "@/router";
import store from "@/store";
class UtilsFunctions {

  constructor() {
  }

  arraysAreEqual(array1, array2) {
    if(!Array.isArray(array1) || !Array.isArray(array2))
      return false;

    if (array1.length !== array2.length)
      return false;

    for (let i = 0; i < array1.length; i++) {
      if (array1[i] !== array2[i]) {
        return false;
      }
    }
    return true;
  }

  handleServerResponseException(err) {
    console.log(err)
    //unauthorized case
    if (err?.response?.status === 400) {
      //TODO SHOW PARAMETERS NOT CORRECT
    }
    else if (err?.response?.status === 401) {
      store.commit('setCredentials', null);
      router.push({name: 'Login'}).catch(() => {})
    }
    //block case
    else if (err?.response?.status === 429) {
      store.commit('setCredentials', null);
      router.push({
        name: 'Login',
        params: {
          redirectUserBlocked: true
        }
      }).catch(() => {
      })
    }
    else if (err?.response?.status === 500 ){
      //TODO SHOW ERROR DIALOG
    }
  }

} export default new UtilsFunctions()