<template>
  <div id="LinearEventsPerNotificationType"></div>
</template>

<script>
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";

export default {
  name: "LinearEventsPerNotificationType",
  data: () => ({
    chartRoot: undefined,
    chart: undefined,
    data: [],
    //Remember to update if event types change, or implement async call to server, not done to decrease time of exec
    eventTypes: [
      {
        "value": 0,
        "key": "NOTIFICATION_GENERIC"
      },
      {
        "value": 1,
        "key": "NOTIFICATION_CVE"
      },
      {
        "value": 2,
        "key": "NOTIFICATION_CERTS"
      },
      {
        "value": 3,
        "key": "NOTIFICATION_PROFILES"
      },
      {
        "value": 4,
        "key": "NOTIFICATION_ENCRYPTION"
      },
      {
        "value": 6,
        "key": "NOTIFICATION_INTEGRITY"
      },
      {
        "value": 7,
        "key": "NOTIFICATION_MALWARE_SCAN"
      },
      {
        "value": 9,
        "key": "NOTIFICATION_DOMAIN"
      },
      {
        "value": 10,
        "key": "NOTIFICATION_URL"
      },
      {
        "value": 11,
        "key": "NOTIFICATION_WIFI"
      },
      {
        "value": 12,
        "key": "NOTIFICATION_PASSCODE"
      },
      {
        "value": 13,
        "key": "NOTIFICATION_OBSOLETE_DEVICE"
      },
      {
        "value": 14,
        "key": "NOTIFICATION_DEBUG"
      },
      {
        "value": 16,
        "key": "NOTIFICATION_CLOUD_BACKUP"
      },
      {
        "value": 17,
        "key": "NOTIFICATION_UNKNOWN_SOURCES"
      },
      {
        "value": 18,
        "key": "NOTIFICATION_OBSOLETE_APP"
      },
      {
        "value": 19,
        "key": "NOTIFICATION_NOT_VALIDATED_APP"
      },
      {
        "value": 20,
        "key": "NOTIFICATION_ADHOC_APP"
      },
      {
        "value": 21,
        "key": "NOTIFICATION_RESTRICTIONS"
      },
      {
        "value": 22,
        "key": "NOTIFICATION_OBSOLETE_OS"
      },
      {
        "value": 23,
        "key": "NOTIFICATION_HOST_BLOCKED"
      },
      {
        "value": 24,
        "key": "NOTIFICATION_IP"
      },
      {
        "value": 25,
        "key": "NOTIFICATION_LOCAL_URL_IP"
      },
      {
        "value": 26,
        "key": "NOTIFICATION_INACTIVE"
      },
      {
        "value": 27,
        "key": "NOTIFICATION_MANAGED_GOOGLE_ACCOUNT_MISSING"
      },
      {
        "value": 28,
        "key": "NOTIFICATION_AV_DB_OUTDATED"
      },
      {
        "value": 29,
        "key": "NOTIFICATION_AV_SCAN_OUTDATED"
      },
      {
        "value": 30,
        "key": "NOTIFICATION_RUNTIME_PERMISSION_CHANGED"
      },
      {
        "value": 31,
        "key": "NOTIFICATION_WRONG_TIME"
      },
      {
        "value": 32,
        "key": "NOTIFICATION_HOST_FILE_MANIPULATED"
      },
      {
        "value": 33,
        "key": "NOTIFICATION_WEB_CONTENT_CATEGORY_MATCH"
      }
    ],
    xAxis: undefined,
    yAxis: undefined,
    notificationTypesInData: [],
    legend: undefined,
  }),
  props: {
    bus: {
      type: Object,
      default: () => {
      }
    },
  },
  mounted() {
    this.renderChart();
    //EXPORT GRAPH
    this.bus.$once("hiddenChildSendMeYourRoot", (DTO) => {
      this.data = DTO.eventsPerNotification;
      this.adjustData();
      this.updateDataSeries();
    });
  },
  beforeDestroy() {
    // removing eventBus listener, otherwise big bugs incoming
    this.bus.$off('hiddenChildSendMeYourRoot');
  },
  methods:{
    renderChart(){
      this.chartRoot = am5.Root.new("LinearEventsPerNotificationType");

      this.chartRoot.setThemes([
        am5themes_Animated.new(this.chartRoot)
      ]);
      this.chart = this.chartRoot.container.children.push(am5xy.XYChart.new(this.chartRoot, {
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        pinchZoomX:true,
        layout: this.chartRoot.verticalLayout
      }));

      this.xAxis = this.chart.xAxes.push(am5xy.CategoryAxis.new(this.chartRoot, {
        categoryField: "date",
        renderer: am5xy.AxisRendererX.new(this.chartRoot, {}),
        tooltip: am5.Tooltip.new(this.chartRoot, {})
      }));

      this.xAxis.data.setAll(this.data);

      this.yAxis = this.chart.yAxes.push(am5xy.ValueAxis.new(this.chartRoot, {
        renderer: am5xy.AxisRendererY.new(this.chartRoot, {})
      }));

      this.legend = this.chart.children.push(am5.Legend.new(this.chartRoot, {
      }));

    },
    createSeries(){
      this.notificationTypesInData.forEach(notificationType => {
        let series = this.chart.series.push(am5xy.LineSeries.new(this.chartRoot, {
          name: notificationType,
          xAxis: this.xAxis,
          yAxis: this.yAxis,
          stacked: true,
          valueYField: notificationType,
          categoryXField: "date",
          tooltip: am5.Tooltip.new(this.chartRoot, {
            pointerOrientation: "horizontal",
            labelText: "[bold]{name}[/]\n{categoryX}: {valueY}"
          })
        }));
        series.fills.template.setAll({
          fillOpacity: 0.9,
          visible: true
        });
        series.data.setAll(this.data);
        let self = this;
        series.bullets.push(function () {
          return am5.Bullet.new(self.chartRoot, {
            sprite: am5.Label.new(self.chartRoot, {
              centerY: am5.p50,
              centerX: am5.p50,
              populateText: true
            })
          });
        });
        this.legend.data.push(series);
      })
    },
    updateDataSeries(){
      this.chart.series.clear();
      this.createSeries();
      this.xAxis.data.setAll(this.data);
      this.bus.$emit('hereMyRootHiddenFather', {
        root: this.chartRoot,
        data: {
          chartType: 'LinearEventsPerNotificationType',
        }
      });
    },
    adjustData(){
      this.data.forEach(event => {
        let keys = Object.keys(event)
        for(const key of keys){
          if(key !== 'date'){
            let eventTypeFounded = this.eventTypes.find(el => el.value == key)
            if(!!eventTypeFounded){
              event[eventTypeFounded.key] = event[key];
              if(!this.notificationTypesInData.find(el => el == eventTypeFounded.key))
                this.notificationTypesInData.push(eventTypeFounded.key);
            }
          }
        }
      })
      this.data.forEach(event => {
        this.notificationTypesInData.forEach(notificationType => {
          if(!event[notificationType])
            event[notificationType] = 0;
        })
      });
    },
  }
}
</script>

<style scoped>
#LinearEventsPerNotificationType {
  width: 1920px;
  height: 1080px;
}
</style>