<template>
  <v-dialog
    v-model="localValue"
    persistent
    max-width="800"
  >
    <v-card style="border-color: red; border-style: solid">
      <v-card-title>
        <v-alert
          dense
          icon="mdi-alert-circle"
          outlined
          prominent
          color="red"
          type="error"
          style="width: 100%"
        >
        Error
        </v-alert>
      </v-card-title>
      <v-card-text>
        <div style="text-align: center; font-size: 20px; color: red">
          {{ getTranslation(this.errorMessage) }}
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="ml-3" color="red" @click="$emit('close')">
          {{ getTranslation('CLOSE') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>


</template>

<script>
import translateService from '@/globalServices/translate'

export default {
  name: "ErrorDialog",
  data: () => ({
    localValue: false,
  }),
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: ""
    }
  },
  methods:{
    getTranslation(key){
      let result = translateService.getTranslation(this.languageChosen,key);
      if(!result)
        return key;
      else
        return result
    },
  },
  watch: {
    value(newVal) {
      this.localValue = newVal;
    },
  },
  computed:{
    languageChosen(){
      return this.$store.state.language;
    },
  }


}
</script>

<style scoped>

</style>