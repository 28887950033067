<template>
  <div>
    <v-data-table
      :height="dynamicHeight"
      :headers="headers"
      :items="items"
      fixed-header
      :class="{divHovered: loadingItems}"
      :item-class="rowTableClasses"
      :footer-props="{
        'items-per-page-text': getTranslation('ROWS_PER_PAGE'),
        'show-current-page': true,
        'show-first-last-page': true,
        'items-per-page-options':[10,15],
       }"
      :options.sync="optionsPagination"
      class="fadeNewRows"
      transition="slide-y-transition"
      @click:row="rowClicked"
    >
      <template v-slot:header.score>
        {{ getTranslation('SEVERITY') }}
      </template>
      <template v-slot:header.client>
        {{ getTranslation('CLIENT') }}
      </template>
      <template v-slot:header.description>
        {{ getTranslation('DESCRIPTION') }}
      </template>
      <template v-slot:header.deviceSerialNumber>
        {{ getTranslation('SERIAL') }}
      </template>
      <template v-slot:header.detectionDate>
        {{ getTranslation('DATE') }}
      </template>
      <template v-slot:header.actions>
        {{ getTranslation('ACTIONS') }}
      </template>
      <template v-slot:item.score="{ item }">
        <div class="d-flex align-center">
          <div
            class="d-flex"
            :style="{'border-left': '5px solid ' + getColorSeverity(item.score), 'padding-left':'8px'}">
            {{ item.score + ' ' + getLabelSeverity(item.score) }}
          </div>
        </div>
      </template>
      <template v-slot:item.client="{ item }">
        <div class="d-flex align-center">
          <div
            class="d-flex">
            {{ item.client?.companyName }}
          </div>
        </div>
      </template>
      <template v-slot:item.description="{ item }">
      <div class="d-flex align-center">
        <v-icon class="pr-1">
          {{ getEventTypeIcon(item.eventTypeKey) }}
        </v-icon>
        {{ getTranslation(item.description) }}
      </div>
    </template>
      <template v-slot:item.detectionDate="{ item }">
        <v-text-field
          :value="getDateString(item.detectionDate)"
          readonly
          outlined
          hide-details="auto"
        >
          <template v-slot:label>
            <v-icon style="vertical-align: middle">
              mdi-calendar
            </v-icon>
          </template>
        </v-text-field>
      </template>
      <template v-slot:item.deviceSerialNumber="{ item }">
        <div class="d-flex align-center">
          <v-icon :color="getColorIconOsType(item.osType)" class="pr-1">
            {{ getNameIconOsType(item.osType) }}
          </v-icon>
          {{ item.deviceName ?? item.deviceSerialNumber }}
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="d-flex align-center">
          <v-btn
            v-if="!item.hasBeenSolved && item.remediationAction && !item.remediationActionStarted"
            color="primary"
            @click.stop="resolveEvent(item); item.checked = true;"
          >
            {{ getTranslation('REPAIR') }}
          </v-btn>
          <v-btn
            v-else-if="!item.hasBeenSolved && !item.remediationAction && !item.remediationActionStarted"
            dense
            @click.stop="redirectToChimpa(item); item.checked = true;"
          >
            {{ getTranslation('MANUAL_REPAIR') }}
          </v-btn>
          <v-btn
            v-else-if="!item.hasBeenSolved && !!item.remediationActionStarted"
            dense
            color="orange"
            @click.stop=""
          >
            {{ getTranslation('COMMAND_SENT') }}
          </v-btn>
          <v-btn
            v-else
            dense
            color="green"
            @click.stop=""
          >
            {{ getTranslation('REPAIRED') }}
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import translateService from "@/globalServices/translate";
import dateService from "@/globalServices/dateService";
import axios from "axios";
import deviceService from "@/globalServices/deviceService";
import utilsFunctions from "@/globalServices/utilsFunctions";


export default {
  name: "LiveDataTable",
  components: {
  },
  data() {
    return {
      headers: [
        {text: '', value: "score", sortable: false, width: "8%"},
        {text: '', value: "client", sortable: false, width: "8%"},
        {text: '', value: "description", sortable: false, width: "40%"},
        {text: '', value: "deviceSerialNumber", sortable: false, width: "15%"},
        {text: '', value: "detectionDate", sortable: false, width: "15%"},
        {text: '', value: "actions", sortable: false, width: "13%"},
      ],
      loadingItems: false,
      periodFilter: undefined,
      optionsPagination: {},
    };
  },
  props: {
    dynamicHeight: {
      type: Number,
      default: 330
    },
    pageSelected: {
      type: Number,
      default: 1
    },
    itemsPerPage: {
      type: Number,
      default: 10
    },
    items: {
      type: Array,
      default: () => []
    },
  },
  mounted() {
  },
  methods: {
    /**
     * Return the color associated with the severity score
     * @param {Number} score
     */
    getColorSeverity(score) {
      if (Number(score) > 9.0) return "#b60000";
      else if (Number(score) >= 7.0 && Number(score) <= 9.0) return "#f02929";
      else if (Number(score) >= 4.0 && Number(score) < 7.0) return "#f27516";
      else if (Number(score) < 4.0) return "#fec601";
      else return "";
    },
    /**
     * Return the label associated with the severity score
     * @param {Number} score
     */
    getLabelSeverity(score) {
      if (Number(score) > 9.0) return this.getTranslation('CRITICAL');
      else if (Number(score) >= 7.0 && Number(score) <= 9.0) return this.getTranslation('HIGH');
      else if (Number(score) >= 4.0 && Number(score) < 7.0) return this.getTranslation('MEDIUM');
      else if (Number(score) < 4.0) return this.getTranslation('LOW');
      else return "";
    },
    async resolveEvent(item) {
      this.$emit('resolveEvent', item);
    },
    rowTableClasses(row) {
      let classes = [];
      if (!row.checked)
        classes.push('not-checked');
      if (!!row.justInserted)
        classes.push('just-inserted');
      return classes.join(" ");
    },
    rowClicked(item, info) {
      this.$emit('rowClicked', {
        items: this.items,
        index: info.index,
        id: item.id,
      });
    },
    async redirectToChimpa(event) {
      axios.defaults.withCredentials = true;
      try {
        const response = await axios
          .get(process.env.VUE_APP_BASEURL + "/api/redirectToChimpa/" + event.clientId)
        if (response.data.success) {
          let host = response.data.payload.clientHost;
          let baseUrl = response.data.payload.clientBaseUrl;
          //const base64Str = Buffer.from(event.deviceSerialNumber, 'utf8').toString('base64');
          window.open('https://' + host + '/' + baseUrl + '/' + '/panel/login', '_blank').focus();
        }
      } catch (e) {
        utilsFunctions.handleServerResponseException(e);
      }
    },
    /**
     * Translates the key with the language chosen, return key only for description case, if is not found in the translations
     * @param {string} key -key to translate
     * @returns {string} key translated
     */
    getTranslation(key) {
      let result = translateService.getTranslation(this.languageChosen, key);
      if (!result)
        return key;
      else
        return result
    },
    /**
     * @param {String} date - date to convert, e.g. 2022-10-12 00:30:21
     * @returns {String}
     */
    getDateString(date) {
      return dateService.getDateSetWithCurrentTimezone(date, this.languageChosen)
    },
    getNameIconOsType(osName) {
      return deviceService.getNameIconOsType(osName);
    },
    getColorIconOsType(osName) {
      return deviceService.getColorIconOsType(osName);
    },
    getEventTypeIcon(eventType) {
      return deviceService.getIconByEventType(eventType);
    },
  },
  watch: {
    items(newVal) {
      setTimeout(() => {
        for(const row of this.items) {
          if(row.justInserted)
            row.justInserted = false;
          else break;
        }
      }, 3200)
    },
    optionsPagination(newVal){
      this.$emit('update:pageSelected', newVal.page);
      this.$emit('update:itemsPerPage', newVal.itemsPerPage);
    }
  },
  computed: {
    languageChosen() {
      return this.$store.state.language;
    }
  }
}
</script>
<style scoped lang="scss">
::v-deep {
  .not-checked {
    background-color: rgba(112, 141, 150, 0.8);
  }
}

::v-deep {
  .fadeNewRows .just-inserted {
    animation: fadeIn 3s;
  }
}

::v-deep {
  td {
    height: 80px !important;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateX(-900px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
</style>
