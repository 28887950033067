<template>
  <v-dialog v-model="localValue" max-width="500px" persistent>
    <v-card style="border-color: #63adf2; border-style: solid">
      <v-card-title style="color: #63adf2"> {{ getTranslation('ACTION_SUMMARY') }}</v-card-title>
      <v-card-text>
        <v-text-field
          :value="item.deviceName ?? item.deviceSerialNumber"
          outlined
          readonly
          :label="getTranslation('SERIAL')"
        >
        </v-text-field>
        <v-text-field
          v-if="!!item.subject"
          :value="item.subject"
          outlined
          readonly
          :label="getTranslation('SUBJECT')"
        >
        </v-text-field>
        <v-textarea
          :value="getTranslation(item.description)"
          outlined
          readonly
          :label="getTranslation('DESCRIPTION')"
        >
        </v-textarea>
        <v-textarea
          :value="getTranslation(item.remediationAction)"
          outlined
          readonly
          :label="getTranslation('ACTION')"
        >
        </v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn color="red"  @click="$emit('close')">{{ getTranslation('CANCEL') }}</v-btn>
        <v-btn color="primary"  @click="resolveEvent" :loading="loaderButton">
          {{ getTranslation('REPAIR') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import translateService from "@/globalServices/translate";
import utilsFunctions from "@/globalServices/utilsFunctions";

export default {
  name: "RepairDialog",
  data: () => ({
    localValue: undefined,
    dialog: false,
    loaderButton: false,
  }),
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => ({
        remediationAction: '',
        description: '',
        deviceSerialNumber: '',
      })
    }
  },
  mounted() {
    this.localValue = this.value;
  },
  methods: {
/*    async resolveEvent(item) {
      this.loaderButton = true;
      axios.defaults.withCredentials = true;
      try {
        let response = await axios
          .post(process.env.VUE_APP_BASEURL + "/api/event/resolve/" + item.id)
        console.log(response);
      } catch (e) {
        console.log(e);
      }
      this.loaderButton = false;
      this.$emit('resolveEvent', item);
    },*/
    async resolveEvent(item) {
      this.loaderButton = true;
      axios.defaults.withCredentials = true;
      if(process.env.VUE_APP_MOD === 'production'){
        try {
          let response = await axios
            .post(process.env.VUE_APP_BASEURL + "/api/event/resolve/" + this.item.id)
          if(response?.data?.success)
            this.$emit('eventResolved', this.item);
        } catch (e) {
          utilsFunctions.handleServerResponseException(e);
        }
        this.loaderButton = false;
      }
      else{
        try {
          let response = await axios
            .put(process.env.VUE_APP_BASEURL + "/api/event/" + this.item.id, {
                hasBeenSolved: true
              },
            );
          if(response?.data?.success)
            this.$emit('eventResolved', this.item);
        } catch (e) {
          utilsFunctions.handleServerResponseException(e);
        }
        this.loaderButton = false;
      }
    },
    /**
     * Translates the key with the language chosen, return key only for description case, if is not found in the translations
     * @param {string} key -key to translate
     * @returns {string} key translated
     */
    getTranslation(key) {
      let result = translateService.getTranslation(this.languageChosen, key);
      if (!result)
        return key;
      else
        return result
    },
  },
  watch: {
    value(newVal){
      this.localValue = newVal;
    },
  },
  computed: {
    languageChosen() {
      return this.$store.state.language;
    }
  }
}
</script>

<style scoped>

</style>