<template>
  <div id="barChartExport"></div>
</template>

<script>
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";

export default {
  name: "BarEventsPerOs",
  data: () => ({
    chartRoot: undefined,
    data: [],
    chart: undefined,
    xAxis: undefined,
    yAxis: undefined,
    windowsSeries: undefined,
    androidSeries: undefined,
    iosSeries: undefined,
    osxSeries: undefined,
    legend: undefined,
  }),
  props: {
    bus: {
      type: Object,
      default: () => {
      }
    },
  },
  mounted() {
    let self = this;
    this.renderChart();
    //EXPORT GRAPH
    this.bus.$once("hiddenChildSendMeYourRoot", (DTO) => {
      this.data = DTO.eventsPerOSByDate;
      self.updateDataSeries();
    });
  },
  beforeDestroy() {
    // removing eventBus listener, otherwise big bugs incoming
    this.bus.$off('hiddenChildSendMeYourRoot');
  },
  methods: {
    renderChart() {
      this.chartRoot = am5.Root.new("barChartExport");
      this.chartRoot.setThemes([
        am5themes_Animated.new(this.chartRoot)
      ]);
      this.chart = this.chartRoot.container.children.push(am5xy.XYChart.new(this.chartRoot, {
        panX: false,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
        layout: this.chartRoot.verticalLayout
      }));
      this.xAxis = this.chart.xAxes.push(am5xy.CategoryAxis.new(this.chartRoot, {
        categoryField: "date",
        renderer: am5xy.AxisRendererX.new(this.chartRoot, {
          rotation: -90,
        }),
        tooltip: am5.Tooltip.new(this.chartRoot, {})
      }));

      this.xAxis.data.setAll(this.data);

      this.yAxis = this.chart.yAxes.push(am5xy.ValueAxis.new(this.chartRoot, {
        min: 0,
        renderer: am5xy.AxisRendererY.new(this.chartRoot, {})
      }));

      this.legend = this.chart.children.push(am5.Legend.new(this.chartRoot, {
        centerX: am5.p50,
        x: am5.p50
      }));
      this.makeSeries("Android", "android", "#34ab3e");
      this.makeSeries("Windows", "windows", "#41a1c3");
      this.makeSeries("Ios", "ios", "#b0757c");
      this.makeSeries("Osx", "osx", "#0070C9");
    },
    makeSeries(name, fieldName, colorHex) {
      let seriesToCreate = this.chart.series.push(am5xy.ColumnSeries.new(this.chartRoot, {
        name: name,
        stacked: true,
        xAxis: this.xAxis,
        yAxis: this.yAxis,
        valueYField: fieldName,
        valueXField: "date",
        categoryField: fieldName,
        categoryXField: "date",
        fill: am5.color(colorHex),
      }));
      seriesToCreate.data.setAll(this.data);
      let self = this;
      seriesToCreate.bullets.push(function () {
        return am5.Bullet.new(self.chartRoot, {
          sprite: am5.Label.new(self.chartRoot, {
            fill: am5.color(colorHex),
            centerY: am5.p50,
            centerX: am5.p50,
            populateText: true
          })
        });
      });
      this.legend.data.push(seriesToCreate);
      if (name === "Android")
        this.androidSeries = seriesToCreate;
      else if (name === "Ios")
        this.iosSeries = seriesToCreate;
      else if (name === "Windows")
        this.windowsSeries = seriesToCreate;
      else if (name === "Osx")
        this.osxSeries = seriesToCreate;
    },
    updateDataSeries() {
      this.xAxis.data.setAll(this.data);
      this.androidSeries.data.setAll(this.data);
      this.iosSeries.data.setAll(this.data);
      this.windowsSeries.data.setAll(this.data);
      this.osxSeries.data.setAll(this.data);
      this.bus.$emit('hereMyRootHiddenFather', {
        root: this.chartRoot,
        data: {
          chartType: 'barEventsPerOs',
        }
      })
    },
  }
}
</script>

<style>
#barChartExport {
  width: 1920px;
  height: 1080px;
}
</style>
