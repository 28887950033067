import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import VueCookies from 'vue-cookies'
import axios from "axios";
import store from "@/store";

Vue.use(VueRouter)
Vue.use(VueCookies)

let routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/',
    name: 'Login',
    props: true,
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/Profile.vue'),
  },
  {
    path: '/logs',
    name: 'logs',
    component: () => import('../views/Log.vue'),
  },
  {
    path: '/distributorsHome',
    name: 'distributorsHome',
    component: () => import('../views/DistributorsHome.vue'),
  },
  {
    path: '/superAdminHome',
    name: 'SuperAdminHome',
    component: () => import('../views/SuperAdminHome.vue'),
  },
  {
    path: '/handleDevices',
    name: 'handleDevices',
    component: () => import('../views/HandleDevices.vue'),
  },
  {
    path: '/handleUsers',
    name: 'handleUsers',
    component: () => import('../views/IamView.vue'),
  },
  {
    path: '/configurations',
    name: 'configurations',
    component: () => import('../views/Configuration.vue'),
  },
  {
    path: "*",
    name: 'Everything',
    component: () => import('../views/Login.vue'),
  }
]

if (process.env.VUE_APP_CLIENT === 'telsy')
  routes.forEach(el => el.path = '/siem' + el.path)

const router = new VueRouter({
  routes,
  mode: "history"
})

router.beforeEach(async (to, from, next) => {
  //try to access a generic page for the first time
  if (!from.name && to.name !== 'Login') {
    try {
      axios.defaults.withCredentials = true;
      const response = await axios.get(
        process.env.VUE_APP_BASEURL + "/api/user/details");
      if (response?.data?.success)
        store.commit('setCredentials', response.data.payload);
      else new Error('Error in authentication')
    } catch (err) {
      console.log(err)
      store.commit('setCredentials', null);
      next({path: '/'});
    }
  }
  next();
})

/*  KEEP COMMENT
  try {
    axios.defaults.withCredentials = true;
    const response = await axios.get(process.env.VUE_APP_BASEURL + "/api/user/details");
    if (response?.data?.success)
      this.$store.commit('setCredentials', response.data.payload);
  } catch (err) {
    console.log(err)
    //unauthorized case
    if (err.response && err.response.status === 401) {
      this.$store.commit('setCredentials', null);
      this.$router.push({name: 'Login'}).catch(() => {
      })
    }
    //block case
    else if (err.response && err.response.status === 429) {
      this.$store.commit('setCredentials', null);
      this.$router.push({
        name: 'Login',
        params: {
          redirectUserBlocked: true
        }
      }).catch(() => {
      })
    }*/


export default router
