<template>
  <div id="PieEventsPerOS"></div>
</template>

<script>
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5percent from "@amcharts/amcharts5/percent";

export default {
  name: "PieEventsPerOS",
  data: () => ({
    chart: undefined,
    chartRoot: undefined,
    series: undefined,
    legend: undefined,
    androidItems: 0,
    iosItems: 0,
    windowsItems: 0,
    osxItems: 0,
  }),
  props: {
    bus: {
      type: Object,
      default: () => {
      }
    },
  },
  mounted() {
    this.renderChart();
    //EXPORT GRAPH
    this.bus.$once("hiddenChildSendMeYourRoot", (DTO) => {
      //KEEP COMMENT DTO.eventsPerOs:{androidItems: [], iosItems:[{num:23, version:13}, ....], windowsItems:[]}
      for (const [keyOS, valueOS] of Object.entries(DTO.eventsPerOS)) {
        let counter = 0
        for (const el of valueOS)
          counter+=el.num
        this[keyOS] = counter;
      }
      this.updateDataSeries();

    });
  },
  beforeDestroy() {
    // removing eventBus listener, otherwise big bugs incoming
    this.bus.$off('hiddenChildSendMeYourRoot');
  },
  methods: {
    renderChart() {
      this.chartRoot = am5.Root.new("PieEventsPerOS");

      this.chartRoot.setThemes([
        am5themes_Animated.new(this.chartRoot)
      ]);
      this.chart = this.chartRoot.container.children.push(
        am5percent.PieChart.new(this.chartRoot, {
          endAngle: 270
        })
      );
    },
    updateDataSeries() {
      this.series = this.chart.series.push(
        am5percent.PieSeries.new(this.chartRoot, {
          valueField: "value",
          categoryField: "category",
          endAngle: 270
        })
      );
      this.series.slices.template.setAll({
        templateField: "sliceSettings",
      });
      this.series.states.create("hidden", {
        endAngle: -90
      });
      this.series.data.setAll([
        {
          category: "Android",
          value: this.androidItems,
          sliceSettings: {
            fill: am5.color("#83afa6")
          }
        },
        {
          category: "Windows",
          value: this.windowsItems,
          sliceSettings: {
            fill: am5.color("#a2a8d3")
          }
        },
        {
          category: "Ios",
          value: this.iosItems,
          sliceSettings: {
            fill: am5.color("#b0757c"),
          }
        },
        {
          category: "Osx",
          value: this.osxItems,
          sliceSettings: {
            fill: am5.color("#0070c9"),
          }
        }
      ].filter(el => el.value > 0));
      this.bus.$emit('hereMyRootHiddenFather', {
        root: this.chartRoot,
        data: {
          chartType: 'PieEventsPerOs',
        }
      });
    }
  }
}
</script>

<style scoped>
#PieEventsPerOS {
  width: 1920px;
  height: 1080px;
}
</style>