<template>
  <div class="px-5">
    <div>
      <v-tabs v-model="tab">
        <v-tab v-for="(tabElement, index) in tabs" :key="index">
          <v-icon class="mr-1">
            {{ tabElement.icon }}
          </v-icon>
          {{ tabElement.title }}
        </v-tab>
        <v-tabs-slider></v-tabs-slider>
      </v-tabs>
      <v-divider></v-divider>
      <v-tabs-items
        v-model="tab"
        class="mt-3"
        :style="{ 'background-color': 'transparent !important' }"
        touchless
      >
        <v-tab-item v-for="subTab in tabs" :key="subTab.name">
          <Dashboard
            v-if="subTab.name === 'monitoring'"
            :windowHeight="windowHeight"
          />
          <StatisticAnalysis
            v-else-if="subTab.name === 'operations'"
            :isTabFocused="tabFocused === 'operations'"
            :windowHeight="windowHeight"
          />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import Dashboard from "@/views/homeComponents/Dashboard.vue";
import StatisticAnalysis from "@/views/homeComponents/StatisticAnalysis.vue";
import axios from "axios"

export default {
  name: "Home",
  components: {
    Dashboard,
    StatisticAnalysis,
  },
  data: () => ({
    tab: 0,
    tabs: [
      {
        title: "Monitoring",
        name: "monitoring",
        icon: "mdi-gauge",
      },
      {
        title: "Operations",
        name: "operations",
        icon: "mdi-cog-stop-outline",
      },
    ],
    tabFocused: 'monitoring',
    modFilter: undefined,
    windowHeight: window.innerHeight
  }),
  props: {},
  created() {
    if (this.user?.rolesUser?.modFilter)
      this.tabs = this.tabs.filter(el => this.user.rolesUser.modFilter === el.name)
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.windowHeight = window.innerHeight;
    });
  },
  methods: {},
  computed: {
    user() {
      return this.$store.state.userModule.userCredentials
    },
  },
  watch: {
    tab(newVal) {
      this.tabFocused = this.tabs[newVal].name
    },
  }
};
</script>

<style>
.v-tabs .theme--dark {
  background-color: transparent !important;
}

.v-toolbar__content {
  padding: 0 !important;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0 grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 5px;
}
</style>
