<template>
  <div id="PieAppUsages"></div>
</template>

<script>
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5percent from "@amcharts/amcharts5/percent";
export default {
  name: "PieAppUsages",
  data: () => ({
    chart: undefined,
    chartRoot: undefined,
    series: undefined,
    appUsageList: new Map(),
  }),
  props: {
    bus: {
      type: Object,
      default: () => {
      }
    },
  },
  mounted() {
    this.renderChart();
    //EXPORT GRAPH
    this.bus.$once("hiddenChildSendMeYourRoot", (DTO) => {
      this.generateData(DTO.appUsages);
      this.updateDataSeries();

    });
  },
  beforeDestroy() {
    // removing eventBus listener, otherwise big bugs incoming
    this.bus.$off('hiddenChildSendMeYourRoot');
  },
  methods: {
    renderChart() {
      this.chartRoot = am5.Root.new("PieAppUsages");

      this.chartRoot.setThemes([
        am5themes_Animated.new(this.chartRoot)
      ]);
      this.chart = this.chartRoot.container.children.push(
        am5percent.PieChart.new(this.chartRoot, {
          endAngle: 270
        })
      );
    },
    updateDataSeries() {
      this.series = this.chart.series.push(
        am5percent.PieSeries.new(this.chartRoot, {
          valueField: "value",
          categoryField: "category",
          endAngle: 270
        })
      );
      this.series.states.create("hidden", {
        endAngle: -90
      });
      let appUsagesToDisplay = [];
      this.appUsageList.forEach((value, key) => {
        appUsagesToDisplay.push({
          category: key,
          value: value
        })
      });
      appUsagesToDisplay.sort((a,b) => a.value - b.value);// sort for better display
      this.series.data.setAll(appUsagesToDisplay);
      this.bus.$emit('hereMyRootHiddenFather', {
        root: this.chartRoot,
        data: {
          chartType: 'PieAppUsages',
        }
      });
    },
    generateData(items){
      items.forEach((appUsage) => {
        if(!!this.appUsageList.get(appUsage.packageName)){
          let appUsedInSeconds = this.appUsageList.get(appUsage.packageName);
          appUsedInSeconds += appUsage.usageTime;
          this.appUsageList.set(appUsage.packageName, appUsedInSeconds);
        }
        else {
          this.appUsageList.set(appUsage.packageName, appUsage.usageTime);
        }
      });
    },
  }
}
</script>

<style scoped>
#PieAppUsages {
  height: 1000px;

}
</style>