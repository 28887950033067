import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    options: {
      customProperties: true
    },
    themes: {
      dark: {
        primary: "#63adf2",
        accent: "#FF4081",
        secondary: "#217779",
        success: "#86af3f",
        info: "#f34fc6",
        warning: "#FB8C00",
        error: "#FF5252",
        background: '#121212',
      },
      light: {
        primary: "#22daff",
        accent: "#ff6b99",
        secondary: "#26ff8c",
        success: "#a5d64c",
        info: "#ff53d0",
        warning: "#ff8e00",
        error: "#ff5252",
      }
    },
  },
});
