<template>
  <div id="LinearNetworkActivities"></div>
</template>

<script>
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";

export default {
  name: "LinearNetworkActivities",
  data: () => ({
    chartRoot: undefined,
    chart: undefined,
    data: [],
    networkActivityGroupedByDate: new Map(),
    xAxis: undefined,
    yAxis: undefined,
  }),
  props: {
    bus: {
      type: Object,
      default: () => {
      }
    },
  },
  mounted() {
    /*    {
          "id": 108,
          "deviceSerialNumber": "ZY22G4K22G",
          "packageName": "com.android.settings",
          "packageName": 16353,
          "bytesOut": 2968,
          "firstTimestamp": "2022-11-17 16:00:00",
          "lastTimestamp": "2022-11-17 17:59:59"
        }*/
    this.renderChart();
    //EXPORT GRAPH
    this.bus.$once("hiddenChildSendMeYourRoot", (DTO) => {
      this.adjustData(DTO.networkActivities);
      this.updateDataSeries();
    });
  },
  beforeDestroy() {
    // removing eventBus listener, otherwise big bugs incoming
    this.bus.$off('hiddenChildSendMeYourRoot');
  },
  methods: {
    renderChart() {
      this.chartRoot = am5.Root.new("LinearNetworkActivities");

      this.chartRoot.setThemes([
        am5themes_Animated.new(this.chartRoot)
      ]);
      this.chart = this.chartRoot.container.children.push(am5xy.XYChart.new(this.chartRoot, {
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        pinchZoomX: true,
        layout: this.chartRoot.verticalLayout
      }));

      this.xAxis = this.chart.xAxes.push(am5xy.CategoryAxis.new(this.chartRoot, {
        categoryField: "date",
        renderer: am5xy.AxisRendererX.new(this.chartRoot, {}),
        tooltip: am5.Tooltip.new(this.chartRoot, {})
      }));

      this.xAxis.data.setAll(this.data);

      this.yAxis = this.chart.yAxes.push(am5xy.ValueAxis.new(this.chartRoot, {
        renderer: am5xy.AxisRendererY.new(this.chartRoot, {})
      }));
      this.legend = this.chart.children.push(am5.Legend.new(this.chartRoot, {}));
    },
    createSeries() {
      ['bytesIn', 'bytesOut'].forEach(typeOfLine => {
        let series = this.chart.series.push(am5xy.SmoothedXLineSeries.new(this.chartRoot, {
          name: typeOfLine,
          xAxis: this.xAxis,
          yAxis: this.yAxis,
          valueYField: typeOfLine,
          categoryXField: "date",
          fill: typeOfLine === "bytesIn" ? am5.color("#2ec700") : am5.color("#e01e02"),
          stroke: typeOfLine === "bytesIn" ? am5.color("#2ec700") : am5.color("#e01e02"),
        }));
        series.data.setAll(this.data);
        let self = this;
        series.bullets.push(function () {
          return am5.Bullet.new(self.chartRoot, {
            sprite: am5.Label.new(self.chartRoot, {
              centerY: am5.p50,
              centerX: am5.p50,
              populateText: true
            })
          });
        });
        this.legend.data.push(series);
        series.strokes.template.setAll({
          strokeWidth: 2
        });
        series.fills.template.setAll({
          fillOpacity: 0.2,
          visible: true
        });
      })
    },
    updateDataSeries() {
      this.chart.series.clear();
      this.createSeries();
      this.xAxis.data.setAll(this.data);
      this.bus.$emit('hereMyRootHiddenFather', {
        root: this.chartRoot,
        data: {
          chartType: 'LinearNetworkActivities',
        }
      });
    },
    adjustData(items){
      //Array({id:1, deviceSerialNumber: "ZY22G4K22G",packageName,bytesIn,bytesOut,firstTimestamp,lastTimestamp})
      items.forEach(networkActivity => {
        let networkActivityStoredInDate = this.networkActivityGroupedByDate.get(networkActivity.firstTimestamp.substring(0, 10))
        if (!networkActivityStoredInDate) {
          this.networkActivityGroupedByDate.set(networkActivity.firstTimestamp.substring(0, 10), {
            bytesIn: networkActivity.bytesIn,
            bytesOut: 0 - networkActivity.bytesOut,
          })
        } else {
          this.networkActivityGroupedByDate.set(networkActivity.firstTimestamp.substring(0, 10), {
            bytesIn: networkActivityStoredInDate.bytesIn + networkActivity.bytesIn,
            bytesOut: networkActivityStoredInDate.bytesOut - networkActivity.bytesOut,
          })
        }
      });
      this.networkActivityGroupedByDate.forEach((value, key) => {
        this.data.push({
          date: key,
          bytesIn: value.bytesIn,
          bytesOut: value.bytesOut,
        })
      });
    },
  }
}
</script>

<style scoped>
#LinearNetworkActivities {
  height: 1000px;
}
</style>